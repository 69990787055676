<template>
  <main>
    <section class="bg-body-1 flex flex-col gap-10 lg:gap-5 lg:grid grid-cols-2 items-center lg:max-w-screen-xl px-3 lg:px-0 lg:mx-auto lg:rounded-2xl mt-11 py-20 lg:py-32">
      <div class="text-white flex flex-col gap-10 justify-center px-5 xl:px-14">
        <h1 class="text-4xl font-bold" style="line-height: 50px;">Inzerce nemovitosti bez realitky a zdarma</h1>
        <p>Inzerujte naprosto ZDARMA! Vyřaďte realitku z hry, neplaťte žádnou provizi a udržte si více peněz v kapse pro sebe!</p>
        <div class="flex justify-center lg:justify-start items-center gap-5">
          <router-link to="/nova-nemovitost" class="bg-body-3 px-5 py-4 rounded-md">Vytvořte nemovitost</router-link>
        </div>
      </div>
      <img width="550" class="rounded-lg" src="/images/alternative-landing-hero.png" />
    </section>
    <FirstSection  
      title="Inzercí přes MakléřeNechci získáte"
      :items="[
        { title: 'Webová stránka inzerce', description: 'Získejte profesionální webovou vizitku Vaší inzerce.', image: '/images/price-tag.png' }, 
        { title: 'Inzerce na sociální sítě', description: 'Jednoduše nasdílejte vizitku na sociální sítě.', image: '/images/safe-house.png' }, 
        { title: 'Inzerce na další portály', description: 'Možnost za poplatek sdílet na další portály.', image: '/images/transparent-selling.png' }
      ]"
      buttonText="Vytvořte nemovitost"
    >
    </FirstSection>
    <SecondSection
      title="Inzerujte zdarma do 5 minut"
      :items="[
        'Vytvořte nemovitost',
        'Registrujte se', 
        'Inzerujte zdarma'
      ]"
    >
    </SecondSection>
    <ThirdSection
      title="Proč inzerovat nemovitost"
      :items="[
        {
          icon: 'eye',
          text: 'Vaše nemovitost bude v popředí zájmu, přilákáme pozornost potenciálních zájemců.'
        },
        {
          icon: 'user',
          text: 'Oslovíte ty, kdo hledají nemovitosti v České republice - vážné zájemce.'
        },
        {
          icon: 'mobile',
          text: 'Snadně vytvoříte profesionální inzerát s fotografiemi a popisy, bez námahy.'
        },
        {
          icon: 'camera',
          text: 'Představte nemovitost pomocí kvalitních fotografií a virtuálních prohlídek.'
        }
      ]"
    >
    </ThirdSection>
    <section id="registration" class="flex flex-col py-24" style="background-color: #e4f5fd;">
      <div class="lg:max-w-screen-lg lg:mx-auto">
        <h2 class="text-5xl font-bold text-center mb-5">Registrace</h2>
        <p class="text-xl font-bold text-center mb-20">Registrujte se zdarma do aplikace MakléřeNechci a k inzerci nemovitosti vás dělí jen několik kliknutí.</p>
        <div class="flex flex-col-reverse lg:grid grid-cols-2 gap-10 lg:gap-36 px-5 xl:px-0">
          <form class="flex flex-col gap-8" v-on:submit.prevent="register()">
            <div class="flex flex-col gap-2">
              <label htmlFor="firstNameAndLastName" class="required">Jméno a příjmení</label>
              <input id="firstNameAndLastName" v-model="userProfile.name" type="text" class="bg-white border border-black py-4 w-full font-bold text-lg rounded-sm px-3" :class="submitted && !$v.userProfile.name.required ? 'border-red-500' : ''" />
              <p v-if="submitted && !$v.userProfile.name.required" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </p>
            </div>
            <div class="flex gap-4">
              <div class="flex flex-col gap-2" style="width: 100px;">
                <label htmlFor="dialcode">Předvolba</label>
                <input id="dialcode" v-model="userProfile.code" @input="plusHandler()" type="text" class="bg-white border border-black py-4 w-full font-bold text-lg rounded-sm px-3" placeholder="+420" />
              </div>
              <div class="flex flex-col gap-2 w-full">
                <label htmlFor="phoneNumber" class="required">Telefon</label>
                <input id="phoneNumber" autocomplete="off" v-model="userProfile.phone" v-mask="priceMask" type="text" class="bg-white border border-black py-4 w-full font-bold text-lg rounded-sm px-3" :class="submitted && !$v.userProfile.phone.required ? 'border-red-500' : ''" />
                <p v-if="submitted && !$v.userProfile.phone.required" class="font-medium text-sm text-red-500">
                  {{ $t("ErrorMessages.FieldIsRequired") }}
                </p>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <label htmlFor="email" class="required">Email</label>
              <input id="email" v-model="userProfile.email" @change="validateEmail()" type="text" class="bg-white border border-black py-4 w-full font-bold text-lg rounded-sm px-3" :class="(submitted || emailChecked) && (!$v.userProfile.email.required || !$v.userProfile.email.email || emailInDatabase) ? 'border-red-500' : ''" />
              <p v-if="(submitted || emailChecked) && !$v.userProfile.email.email" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.EmailBadFormat") }}
              </p>
              <div v-if="(submitted || emailChecked) && !$v.userProfile.email.required" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </div>
              <div v-if="emailInDatabase" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.EmailAlreadyExist") }}
                <p>
                  <button class="text-body-1" @click="login()">Přihlásit?</button>
                </p>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <label htmlFor="password" class="required">Heslo</label>
              <input id="password" v-model="userProfile.password" type="password" class="bg-white border border-black py-4 w-full font-bold text-lg rounded-sm px-3" :class="submitted && !$v.userProfile.password.required ? 'border-red-500' : ''" />
              <p v-if="submitted && !$v.userProfile.password.required" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </p>
              <p v-if="submitted && !$v.userProfile.password.minLength" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.PasswordMinLength") }}
              </p>
            </div>
            <div class="flex flex-col gap-2">
              <label htmlFor="passwordAgain" class="required">Zopakovat heslo</label>
              <input id="passwordAgain" v-model="userProfile.passwordAgain" type="password" class="bg-white border border-black py-4 w-full font-bold text-lg rounded-sm px-3" :class="(submitted && !$v.userProfile.passwordAgain.required) || (submitted && !$v.userProfile.passwordAgain.sameAsPassword) ? 'border-red-500' : ''" />
              <div v-if="submitted && !$v.userProfile.passwordAgain.required" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </div>

              <div v-if="submitted && !$v.userProfile.passwordAgain.sameAsPassword" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.PasswordsAreNotSame") }}
              </div>
            </div>
            <div class="flex items-center gap-2">
              <div class="flex items-center gap-2">
                <input id="gdprAgreement" v-model="userProfile.agree" type="checkbox" class="w-5 h-5 cursor-pointer" :style="submitted && userProfile.agree === false ? 'height: 34px; background: red; paddingTop: 1px; paddingLeft: 1px; paddingRight: 1px; borderRadius: 4px; ' : ''" />
                <label htmlFor="gdprAgreement">Souhlasím se zpracováním osobních údajů.</label>
              </div>
              <p v-if="submitted && userProfile.agree === false" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </p>
            </div>
            <button type="submit" class="bg-body-3 text-white py-4 w-full font-bold text-lg rounded-md">Zaregistrovat se zdarma</button>
          </form>
          <div class="flex flex-col justify-center gap-10">
            <img class="rounded-lg" src="/images/alternative-landing-registration.png" />
            <ul class="flex flex-col gap-16 text-2xl list">
              <li class="flex items-center gap-5 font-bold">
                <div class="w-6 h-6 bg-body-3 rounded-full flex justify-center items-center">
                  <img width="12" src="/images/checkmark.png" />
                </div>
                Podejte zdarma inzerát
              </li>  
              <li class="flex items-center gap-5 font-bold">
                <div class="w-6 h-6 bg-body-3 rounded-full flex justify-center items-center">
                  <img width="12" src="/images/checkmark.png" />
                </div>
                Bezplatná inzerce nemovitostí
              </li> 
              <li class="flex items-center gap-5 font-bold">
                <div class="w-6 h-6 bg-body-3 rounded-full flex justify-center items-center">
                  <img width="12" src="/images/checkmark.png" />
                </div>
                Inzerce nemovitostí bez realitky
              </li>  
            </ul>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import FirstSection from "../components/landingPages/FirstSection/FirstSection";
import SecondSection from "../components/landingPages/SecondSection/SecondSection";
import ThirdSection from "../components/landingPages/ThirdSection/ThirdSection";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import axios from "axios";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { getUserEmail } from "../services/rest/services";

const currencyPriceMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
  integerLimit: 12,
});
export default {
  name: "LandingPageInzerce",
  components: {
    FirstSection,
    SecondSection,
    ThirdSection
},
  data() {
    return {
      codeCantIncludes:
        "abcdefghcijklmnopqrstuvwxyzABCDEFGHCIJKLMNOPQRSTUVWXYZ",
      userProfile: {
        agree: false,
        code: "+420",
      },
      emailChecked: false,
      submitted: false,
      userJwt: "",
      emailInDatabase: false,
      priceMask: currencyPriceMask,
    };
  },
  methods: {
    async register() {
      this.emailInDatabase = false;
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid || this.userProfile.agree === false) {
        return window.localStorage.setItem("registrace", false);
      } else {
        try {
          const emails = await getUserEmail(this.userProfile.email);
          if (emails.email) {
            window.localStorage.setItem("registrace", false);
            return (this.emailInDatabase = true);
          } else if (!emails.email) {
            if (this.userProfile.code === "+") {
              this.userProfile.code = "";
            }
            // cant includes letters!
            const letterArray = this.codeCantIncludes.split("");
            letterArray.forEach((letter) => {
              if (this.userProfile.code.includes(letter)) {
                this.userProfile.code = "";
              }
            });
            await axios.post(
              `${process.env.VUE_APP_STRAPI_API_URL}/auth/local/register`,
              {
                username: this.userProfile.email,
                email: this.userProfile.email,
                nameSurname: this.userProfile.name,
                password: this.userProfile.password,
                phone: this.userProfile.code + this.userProfile.phone,
              }
            );
            Swal.fire(this.$t("SuccessMessages.SuccessfullyRegistered"));
          }
          this.$router.push("/potvrzeni-registrace-emailem");
        } catch (e) {
          return e;
        }
      }
    },
    plusHandler() {
      if (this.userProfile.code.length === 0) {
        this.userProfile.code = "+";
      }
      if (this.userProfile.code.length > 5) {
        this.userProfile.code = "+";
      }
    },
    validateEmail() {
      this.emailChecked = true;
      this.$v.$touch();
    },
    login() {
      window.localStorage.clear();
      this.$router.push("prihlaseni");
    },
  },
  validations: {
    userProfile: {
      name: { required },
      phone: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) },
      passwordAgain: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs("password"),
      },
      agree: { required },
    },
  },
};
</script>

<style scoped>
.list {
  @apply flex flex-col gap-7;
}
.list li {
  @apply grid items-center gap-5 font-bold;
  grid-template-columns: 20px 1fr;
}
.list-sell li {
  @apply grid border-b py-2 items-center lg:flex lg:justify-between;
  grid-template-columns: 1fr 100px;
}
.list-sell li p:last-child {
  @apply text-right;
}
.required::after {
  content: "*";
}
</style>
