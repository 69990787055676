<template>
  <section class="flex flex-col justify-center gap-16 lg:max-w-screen-xl mx-3 xl:mx-auto px-5 py-20 lg:px-0 lg:py-40">
    <h2 class="font-bold text-4xl text-center">{{ title }}</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5 gap-y-10 xl:gap-y-0">
      <ThirdSectionItem v-for="item in items" :key="item.text" :text="item.text" :icon="item.icon" />
    </div>
  </section>
</template>

<script>
import ThirdSectionItem from './ThirdSectionItem.vue';

export default {
    name: "ThirdSection",
    props: {
      title: String,
      items: Array,
    },
    components: { ThirdSectionItem }
}
</script>