<template>
  <section class="flex flex-col justify-center gap-16 lg:max-w-screen-xl lg:mx-auto px-5 py-20 lg:px-0 lg:py-40">
    <h2 class="text-4xl text-center font-bold">{{ title }}</h2>
    <ul class="flex flex-col items-center lg:items-start lg:flex-row gap-10 justify-center">
      <FirstSectionItem v-for="item in items" :key="item.title" :title="item.title" :description="item.description" :image="item.image" />
    </ul>
    <router-link to="/nova-nemovitost" class="bg-body-3 px-5 py-3 text-white rounded-md mx-auto w-fit">{{ buttonText }}</router-link>
  </section>
</template>

<script>
import FirstSectionItem from './FirstSectionItem.vue';

export default {
    name: "FirstSection",
    props: {
        title: String,
        items: Array,
        buttonText: String,
    },
    components: { FirstSectionItem }
}
</script>