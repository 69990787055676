<template>
  <div class="flex flex-col gap-7">
    <div class="w-16 h-16 bg-white text-body-1 mx-auto flex justify-center items-center rounded-full font-bold text-3xl">{{ number }}</div>
    <p class="bg-white text-body-1 rounded-md px-10 xl:px-5 py-3 text-center font-bold uppercase mx-auto xl:mx-0 w-fit xl:w-full">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "SecondSection",
  props: {
    number: Number,
    text: String,
  }
}
</script>