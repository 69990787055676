<template>
  <section class="bg-body-1 text-white">
    <div class="text-lg grid grid-cols-1 xl:grid-cols-3 gap-10 py-20 lg:px-0 lg:py-40 lg:max-w-screen-xl mx-3 xl:mx-auto">
      <h2 class="font-bold text-4xl text-center col-span-1 xl:col-span-3">{{ title }}</h2>
      <SecondSectionItem v-for="(item, index) in items" :key="item" :number="index + 1" :text="item" />
    </div>
  </section>
</template>

<script>
import SecondSectionItem from './SecondSectionItem.vue';

export default {
    name: "SecondSection",
    props: {
      title: String,
      items: Array,  
    },
    components: { SecondSectionItem }
}
</script>