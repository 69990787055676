<template>
  <div class="relative border-2 border-body-1 rounded-md shadow-xl h-auto flex flex-col items-center p-7">
    <div class="border-2 border-body-1 p-3 rounded-full absolute w-14 h-14 -top-8 bg-white flex justify-center items-center">
      <font-awesome-icon :icon="icon" style="color: #1b26e2; font-size: '40px';" />
    </div>
    <h4 class="text-center font-semibold">{{ text }}</h4>
  </div>
</template>

<script>
export default {
  name: "ThirdSection",
  props: {
    icon: String,
    text: String,
  }
}
</script>