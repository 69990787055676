<template>
  <li class="flex flex-col gap-4 lg:gap-7 text-center" style="width: 300px;">
    <img class="mx-auto" height="35" :src="image" />
    <h4 class="text-2xl font-bold">{{ title }}</h4>
    <p>{{ description }}</p>
  </li>
</template>

<script>
export default {
  name: "FirstSectionItem",
  props: {
    title: String,
    description: String,
    image: String,
  }
}
</script>